import { Component } from '@angular/core';
import { Filter } from './filter';
import { Clinic } from './clinic';
import jsonData from './data.json';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'clinic-widget';
  public filterList:Filter[] = jsonData['filters'];
  private clinicList:Clinic[] = jsonData['clinics'];
  private selectedFilterList:Filter[] = [];
  public selectedClinicList:Clinic[] = this.clinicList;

  private setSelectedFilterList() {
    this.selectedFilterList = [];
    
    //get list of all checked checkbox, assuming no checkboxes other than the filters are on the page
    var activeButtonList:any = document.querySelectorAll("input[type='checkbox']:checked");

    for (let item of activeButtonList) {
      //get current filter name
      var filterName = item.parentElement.innerText.toLowerCase().trim();

      //get filter head of current category
      var filterHeadName = item.parentElement.parentElement.parentElement.parentElement.firstElementChild.innerText.toLowerCase().trim();

      //category already exist 
      if (this.selectedFilterList.find(list => list.category === filterHeadName)) {
        this.selectedFilterList.find(list => list.category === filterHeadName).subCategory.push(filterName);
      } 
      else {
        this.selectedFilterList.push(new Filter(filterHeadName,[filterName]));
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      var acc = document.getElementsByClassName("accordion");
      var i;
      
      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
          this.classList.toggle("active");
          var panel = this.nextElementSibling;
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
          } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
          } 
        });
      }
    }, 1000);
}

  private setSelectedClinicList() {
    this.selectedClinicList = [];

    //nested for loops for each sub category
    for (var item of this.selectedFilterList) {
      for (var subItem of item.subCategory){

        //get list of all clinics matched with current category and subCategory
        var clinicList = this.clinicList.filter(list => list.filters.find(filter => filter.category.toLowerCase() === item.category && filter.subCategory.find(subFilter => subFilter.toLowerCase() === subItem)))

        for(var clinic of clinicList) {

          //both clinic and category already exist
          if (this.selectedClinicList.find(list => list.name ===  clinic.name && list.filters.find(filter => filter.category === item.category))) {
              this.selectedClinicList.find(list => list.name ===  clinic.name && list.filters.find(filter => filter.category === item.category).subCategory.push(subItem));
              this.selectedClinicList.find(list => list.name ===  clinic.name && list.filters.find(filter => filter.category === item.category)).rank++;
            }

          //only clinic already exist
          else if (this.selectedClinicList.find(list => list.name ===  clinic.name)) {
            this.selectedClinicList.find(list => list.name ===  clinic.name).filters.push(new Filter(item.category,[subItem]));
            this.selectedClinicList.find(list => list.name ===  clinic.name).rank++;
          }

          //nothing already exist
          else{
            this.selectedClinicList.push(new Clinic(clinic.name, clinic.description, clinic.url, [new Filter(item.category, [subItem])], 1));
          }
        }
      }
    }
  }

  private renameSubCategories() {
    this.selectedClinicList.find(list => list.filters.find(filter => filter.subCategory.forEach(function (part, index) {
      filter.category.toLowerCase() === "DC Bar Certification Required".toLowerCase() && filter.subCategory[index].toLowerCase() === "Yes".toLowerCase() ? filter.subCategory[index] = "DC Bar Certification Required" : filter.subCategory[index];
      filter.category.toLowerCase() === "DC Bar Certification Required".toLowerCase() && filter.subCategory[index].toLowerCase() === "No".toLowerCase() ? filter.subCategory[index] = "DC Bar Certification Not Required" : filter.subCategory[index];
    })));
  }

  private sortSelectedClinicList(){
    this.selectedClinicList.sort((a, b) => (a.rank < b.rank) ? 1 : (a.rank === b.rank) ? (a.name > b.name) ? 1 : -1 : -1)
  }

  private removeActive() {
    var inputs:NodeListOf<HTMLInputElement> = document.querySelectorAll("input[type='checkbox']:checked");
      for(var i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
        inputs[i].parentElement.classList.remove("active");
    }
  }

  search() {
    this.setSelectedFilterList();
    if(this.selectedFilterList.length === 0){
      this.clear();
    }
    else {
      this.setSelectedClinicList();
      this.renameSubCategories();
      this.sortSelectedClinicList();
    }
  }

  clear() {
    this.selectedClinicList = this.clinicList;
    this.removeActive();
  }
}