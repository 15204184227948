<!--IO: Header Panel-->
  <header id="header" class="header" itemscope="" itemtype="http://schema.org/WPHeader" aria-label="Main Header">
    <div class="fs-row">
      <div class="fs-cell">
        <div class="header_wrap">
          <div class="logo" itemscope="" itemtype="http://schema.org/Organization">
            <a class="logo_link" itemprop="url" href="https://www.law.georgetown.edu/">
              <img src="https://cps.appointlink.com/assets/img/GeorgetownLaw_HeaderLogo.png" alt="Georgetown Law" class="responsive">
            </a>
          </div>
          <a class="js-nav_handle mobile_sidebar_handle fs-navigation-handle fs-navigation-overlay-handle fs-navigation-overlay-right-handle fs-navigation-handle__1 fs-navigation__1 fs-light fs-swap-element" href="#mobile_sidebar" id="main_mobile_sidebar_handle" data-swap-target=".fs-navigation__1" data-swap-linked="fs-navigation-handle__1" data-swap-group="fs-navigation" tabindex="0">Menu</a>
        </div>
        <div class="header_main_wrap">
          <nav id="main_nav" class="js-main-nav main_nav main_nav_lg" itemscope="" itemtype="http://schema.org/SiteNavigationElement" aria-label="Main Site">
            <ul class="menu" role="menubar" aria-label="Main Site">
              <li class="menu-item-main menu-item menu-item-type-post_type menu-item-object-page js-main-nav-1  menu-item-has-children fs-swap-target fs-swap-enabled" role="presentation">
                <a href="https://www.law.georgetown.edu/admissions-aid/">Admissions &amp; Aid</a>
                
              </li>
              <li class="menu-item-main menu-item menu-item-type-post_type menu-item-object-page js-main-nav-2  menu-item-has-children fs-swap-target fs-swap-enabled" role="presentation">
                <a href="https://www.law.georgetown.edu/academics/">Academics</a>
                
              </li>
              <li class="menu-item-main menu-item menu-item-type-post_type menu-item-object-page js-main-nav-3  menu-item-has-children fs-swap-target fs-swap-enabled" role="presentation">
                <a href="https://www.law.georgetown.edu/experiential-learning/">Experiential Learning</a>
                
              </li>
              <li class="menu-item-main menu-item menu-item-type-post_type menu-item-object-page js-main-nav-4 " role="presentation">
                <a href="https://www.law.georgetown.edu/faculty/">Faculty &amp; Research</a>
              </li>
              <li class="menu-item-main menu-item menu-item-type-post_type menu-item-object-page js-main-nav-5  menu-item-has-children fs-swap-target fs-swap-enabled" role="presentation">
                <a href="https://www.law.georgetown.edu/your-life-career/">Your Life &amp; Career</a>
                
              </li>
            </ul>
          </nav>

          <a class="site_search_handle" href="https://www.law.georgetown.edu/search/">
            <span class="site_search_handle_label">Search</span>
            <span class="site_search_handle_icon">
             
            </span>
          </a>
        </div>
      </div>
    </div>
  </header>
    <!--IO: Header Panel-->
    <!--IO: Main Panel-->
    <div class="container">
      <div class="row" style="padding-top: 60px;">
        <!--IO: Left Panel-->
        <div class="col-sm-6 col-lg-8">
          <div class="leftpanel">
            <div class="page_header">
              <div class="breadcrumb_nav">
                <div class="breadcrumb_list">
                  <div class="breadcrumb_item">
                    <a class="breadcrumb_link" href="https://www.law.georgetown.edu/">
                      <span class="breadcrumb_name">
                        <i class="fa fa-home" aria-hidden="true" style="padding-right: 15px;font-size: 12pt"></i>
                        Home
                      </span>
                    </a>
                    <meta itemprop="position" content="1">
                  </div>
                  <div class="breadcrumb_item">
                    <a class="breadcrumb_link" href="https://www.law.georgetown.edu/experiential-learning/">
                      <span class="breadcrumb_name">Experiential Learning</span>
                    </a>
                    <meta itemprop="position" content="2">
                  </div>
                  <div class="breadcrumb_item">
                    <a class="breadcrumb_link" href="https://www.law.georgetown.edu/experiential-learning/clinics/">
                      <span class="breadcrumb_name">Clinics</span>
                    </a>
                    <meta itemprop="position" content="3">
                  </div>
                  <div class="breadcrumb_item">
                    <span class="breadcrumb_label">
                      <span class="breadcrumb_name">Search Clinical Programs</span>
                    </span>
                    <meta itemprop="position" content="4">
                  </div>
                </div>
              </div>
              <!--IO: End Breadcrumbs-->
              <div class="page_intro">
                <h1 class="page_title">Find the Right Clinical Program<br/>for You</h1>
              </div>

              <div class="typography">
                
                <p>
                  
                    Match your interests by using the filters on the right to narrow the list of clinics showing
                    below.
                 
                </p>
                <h2>Available Clinics Include:</h2>
              </div>
              <!--IO: Clinic Listing-->
              <div>
                <section class="clinic_listing" aria-label="Clinic Listing">
                  <!--IO: Order the result listing by title unless the user has selected filters.  If the user has selected filters, show the clinics that have the most tags matched at the top, the least at the bottom. Secondarily sort by clinic title of # matched tags is the same.-->
                  <div class="clinicitem_listing" *ngFor="let clinic of selectedClinicList">
                    <h3><button id="expanddesc" (click)="clinic.desc= !clinic?.desc"><i *ngIf="!clinic?.desc" class="fa fa-plus" style="font-size:14pt"></i><i *ngIf="clinic?.desc" class="fa fa-minus" style="font-size:14pt"></i></button><a class="clinicitem_listing_title" href={{clinic.url}}>{{clinic.name}}</a></h3>
                    
                      <p [ngClass]="{'clinicitem_listing_description': clinic?.desc, 'clinicitem_listing_description_truncated' : !clinic?.desc}">{{clinic.description}}</p>
                   
                    <div class="clinicfiltertags_container" *ngIf="clinic.rank">
                      <span *ngFor="let item of clinic.filters">
                        <span *ngFor="let subItem of item.subCategory">
                          <button class="clinicfiltertags active unclickable">{{subItem}}</button>
                        </span>
                      </span>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <!--IO: Left Panel-->
        <!--IO: Right Panel-->
        <div class="col-sm-6 col-lg-4">
          <div class="rightpanel">
            <div class="m-t-80 m-b-20">
              <div class="clinicfilter_header">
                
                <span class="clinicfilter_header_title">Clinic Filters</span>
                <span (click)="clear()" class="clinicfilter_header_clearlink clickable">(Clear to Show All)</span>
              </div>
              <div class="clinicfilteroption_container">
                <div class="p-2">

                  <!--IO: This next div should be a loop that renders the list of filter options-->
                  <div class="clinicfilteroptionitems_container" *ngFor="let item of filterList">
                    <div class="accordion m-t-0 m-b-0">{{item.category}}</div>
                    <div class="panel">
                      <span class="btn-group btn-group-toggle m-15" *ngFor="let subItem of item.subCategory">
                        <label class="btn-simple clinicfiltertags" ngbButtonLabel>
                          <input (click)="search()" type="checkbox" ngbButton> {{subItem}}
                        </label>
                      </span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--IO: Right Panel-->
      </div>
    </div>
    <footer class="footer" id="footer" itemscope="" itemtype="http://schema.org/WPFooter">
      <div class="fs-row">
        <div class="fs-cell">
          
          <div class="footer_bottom">
            <div class="logo_footer" aria-hidden="true">
              <a href="https://www.law.georgetown.edu/">
                <img src="https://cps.appointlink.com/assets/img/GeorgetownLaw_FooterLogo.png" alt="Georgetown Law" class="responsive">
                </a>
</div>
            <div class="footer_address" itemscope="" itemtype="http://schema.org/PostalAddress">
              <span class="footer_address_name" itemprop="name">Georgetown Law</span>
              <span class="footer_address_street" itemprop="streetAddress">600 New Jersey Avenue NW</span>
              <span class="footer_address_city" itemprop="addressLocality">Washington DC 20001</span><br>
              <a class="footer_address_phone" itemprop="telephone" href="tel:+12026629000">202.662.9000</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!--IO: Main Panel-->
